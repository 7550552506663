import "./App.css";
import "antd/dist/antd.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "./page/home/home";
// eslint-disable-next-line
import Login from "./page/login/login";

function App() {
  return (
    <>
      <Router>
        <Route exact path="/" component={Login} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/admin" component={Home} />
        <Route exact path="/code" component={Home} />
      </Router>
    </>
  );
}

export default App;
