import React, { Component } from 'react'

import { Modal, Form, Input, Button } from 'antd'

import UserService from '../../services/UserService'
import ListUser from '../Users/listUser'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const validateMessages = {
  // eslint-disable-next-line
  required: '${label} is required!',
  // eslint-disable-next-line
  types: {
    // eslint-disable-next-line
    email: '${label} is not validate email!',
    // eslint-disable-next-line
    number: '${label} is not a validate number!',
  },
  number: {
    // eslint-disable-next-line
    range: '${label} must be between ${min} and ${max}',
  },
}
// eslint-disable-next-line
class EditUser extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
  }

  state = {
    visible: this.props.visible,
    data: this.props.data,
    usersValue: [],
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    this.props.onClose(true)
  }

  handleDelete = (e) => {
    console.log('Delete')
  }

  handleAdd = async (e) => {
    const newData = {
      phoneNumber: e.phone,
      lineId: e.line_id,
      bankAccountNumber: e.bank_number,
      agentId: e.agent_id,
      bank: e.bank,
    }

    await UserService.createUser(newData)
      .then((response) => {
        console.log(response.data)
      })
      .catch((e) => {
        console.log(e)
      })
    this.handleCancel()
  }

  render() {
    return (
      <div>
        <Modal
          destroyOnClose={true}
          title="เพิ่มสมาชิก"
          visible={this.props.visible}
          onCancel={this.handleCancel}
          footer={[]}
        >
          <div style={{ display: 'none' }}>
            <ListUser dataValue={this.state.usersValue} />;
          </div>
          <Form
            {...layout}
            name="nest-messages"
            onFinish={this.handleAdd}
            initialValues={{
              // eslint-disable-next-line
              ['user_id']: this.props.data.user_id,
              // eslint-disable-next-line
              ['phone']: this.props.data.phone_number,
              // eslint-disable-next-line
              ['bank']: this.props.data.bank,
              // eslint-disable-next-line
              ['bank_number']: this.props.data.bank_number,
              // eslint-disable-next-line
              ['created_at']: this.props.data.created_at,
              // eslint-disable-next-line
              ['line_id']: this.props.data.line_id,
              // eslint-disable-next-line
              ['agent_id']: this.props.data.agent_id,
            }}
            validateMessages={validateMessages}
          >
            <Form.Item
              name="user_id"
              label="ชื่อผู้ใช้"
              // rules={[{ required: true }]}
            >
              <Input
                defaultValue={this.state.data.user_id}
                bordered={false}
                style={{ color: 'black', fontWeight: 'bold' }}
                disabled
              />
            </Form.Item>

            <Form.Item
              name="phone"
              label="เบอร์โทร"
              // rules={[{ required: true }]}
            >
              <Input
                defaultValue={this.props.data.phone_number}
                bordered={false}
                style={{ color: 'black', fontWeight: 'bold' }}
                disabled
              />
            </Form.Item>
            <Form.Item
              name="bank"
              label="ธนาคาร"
              // rules={[{ required: true }]}
            >
              <Input
                defaultValue={this.props.data.bank}
                bordered={false}
                style={{ color: 'black', fontWeight: 'bold' }}
                disabled
              />
            </Form.Item>
            <Form.Item
              name="bank_number"
              label="เลขบัญชี"
              // rules={[{ required: true }]}
            >
              <Input
                defaultValue={this.props.data.bank_number}
                bordered={false}
                style={{ color: 'black', fontWeight: 'bold' }}
                disabled
              />
            </Form.Item>
            <Form.Item
              name="created_at"
              label="วันที่สมัคร"
              // rules={[{ required: true }]}
            >
              <Input
                defaultValue={this.props.data.created_at}
                bordered={false}
                style={{ color: 'black', fontWeight: 'bold' }}
                disabled
              />
            </Form.Item>
            <Form.Item
              name="line_id"
              label="ไลน์ ไอดี"
              // rules={[{ required: fa }]}
            >
              <Input
                defaultValue={this.props.data.line_id}
                bordered={false}
                style={{ color: 'black', fontWeight: 'bold' }}
                disabled
              />
            </Form.Item>
            <Form.Item name="agent_id">
              <Input defaultValue={this.props.data.agent_id} hidden />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button
                // style={{ float: "right" }}
                key="submit"
                type="primary"
                htmlType="submit"
              >
                เพิ่ม
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default EditUser
