import React, { useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faPiggyBank,
//   faMoneyBillAlt,
//   faSortAmountUp,
//   faUserFriends,
//   faSignal,
// } from "@fortawesome/free-solid-svg-icons";
import Nav from "../Navbar/navbar";

import UserService from "../../services/UserService";

// import { Link } from "react-router-dom";

import { Layout, Menu } from "antd";
// import { TeamOutlined } from "@ant-design/icons";
// import Chart from "../Chart/index";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import UserManagement from "../Users/index";
import Admin from "../Users/admin";
import CodeCredit from "../formAddCredit";
// import Details from "../Details/details";
// import FadeIn from 'react-fade-in'
// import Lottie from 'react-lottie'
// import * as loadingData from '../../loading.json'
// import * as successData from '../../success.json'

// eslint-disable-next-line
const { Header, Content, Sider } = Layout;

// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: loadingData.default,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice",
//   },
// };
// const defaultOptions2 = {
//   loop: true,
//   autoplay: true,
//   animationData: successData.default,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice",
//   },
// };

function Home() {
  // const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);
  // const [users, setUser] = useState();

  const user = localStorage.getItem("user");

  // eslint-disable-next-line

  useEffect(() => {
    // eslint-disable-next-line
    const interval = setInterval(() => {
      let exp = localStorage.getItem("token");
      if (exp != null) {
        UserService.expires(exp);
      }
    }, 1000);
    // setTimeout(() => {
    //   fetch("https://jsonplaceholder.typicode.com/posts")
    //     .then((response) => response.json())
    //     .then((json) => {
    //       setLoading(true);
    //       setTimeout(() => {
    //         setSuccess(true);
    //       }, 1000);
    //     });
    // }, 1500);
    // await UserService.userToday()
    //   .then((response) => {
    //     setUser(response.data);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }, []);

  return (
    <>
      {user ? (
        <Router>
          <Layout style={{ height: "100vh" }}>
            <Header className="header">
              <Nav />
            </Header>
            <Layout>
              <Sider breakpoint="xl" collapsedWidth="0">
                <Menu
                  theme="light"
                  mode="inline"
                  // defaultSelectedKeys={["1"]}
                  defaultOpenKeys={["sub1"]}
                  style={{
                    height: "100%",
                    borderRight: 0,
                    paddingTop: "20px",
                  }}
                >
                  <Menu.Item key="1">
                    <Link to="/home">ข้อมูลผู้ใช้</Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link to="/admin">ข้อมูลผู้ดูแลระบบ</Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Link to="/code">สร้างโค้ดเติมเครดิต</Link>
                  </Menu.Item>
                </Menu>
              </Sider>
              <Layout style={{ padding: "0 24px 24px" }}>
                <Content
                  style={{
                    height: "100%",
                    marginTop: "30px",
                    padding: "0 30px",
                    width: "100%",
                  }}
                >
                  <Route exact path="/home" component={UserManagement} />
                  <Route path="/admin" component={Admin} />
                  <Route path="/code" component={CodeCredit} />
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Router>
      ) : (
        (window.location.href = "/")
      )}

      <style jsx>{`
        .custom-display {
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
        .card-size {
          width: 100%;
        }
        .ant-card-body {
          padding: 1px;
        }
        .row-margin {
          margin-top: 10px;
        }
        .text-algin {
          text-align: right;
          margin-right: 5%;
        }

        .site-layout-content {
          min-height: 280px;
          padding: 24px;
          background: #fff;
        }
        #components-layout-demo-top .logo {
          float: left;
          width: 120px;
          height: 31px;
          margin: 16px 24px 16px 0;
          background: rgba(255, 255, 255, 0.3);
        }
        .ant-row-rtl #components-layout-demo-top .logo {
          float: right;
          margin: 16px 0 16px 24px;
        }
      `}</style>
    </>
  );
}

export default Home;
