import React, { Component } from "react";
import { Space } from "antd";
import EditUser from "../FormEditUser/index";
import ViewUser from "../FormEditUser/viewUsers";
import UserService from "../../services/UserService";
import ListUser from "./listUser";
import DeleteUser from "../FormEditUser/deleteUser";

class UserManagement extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  state = {
    visible1: false,
    visible2: false,
    visibleDelete: false,
    type: "PreUser",
    modalData: "",
    modalView: "",
    loadings: [],
    preUsers: [],
    users: [],
  };

  reLoad = async (status) => {
    // if (status) {
    //   await UserService.getPreUser()
    //     .then((response) => {
    //       this.setState({ preUsers: response.data });
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // }
  };

  async componentDidMount() {
    let exp = localStorage.getItem("token");
    if (exp != null) {
      UserService.expires(exp);
    }
    // await UserService.getPreUser()
    //   .then((response) => {
    //     this.setState({ preUsers: response.data });
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }

  showModal1 = (text) => {
    this.setState({
      visible1: true,
      modalView: text,
    });
  };

  showModal2 = (text) => {
    this.setState({
      visible2: true,
      modalData: text,
    });
  };

  onCloseEdit1 = (status) => {
    if (status) {
      this.setState({
        visible1: false,
        modalView: "",
      });
    }
  };

  handleOk1 = (e) => {
    console.log(e);
    this.setState({
      visible1: false,
    });
  };

  handleCancel1 = (e) => {
    console.log(e);
    this.setState({
      visible1: false,
      visibleDelete: false,
    });
  };

  handleDelete1 = (e) => {
    console.log("Delete");
  };

  showModalDelete = (text) => {
    this.setState({
      visibleDelete: true,
      modalView: text,
    });
  };

  onCloseEdit = (status) => {
    console.log(status);
    if (status) {
      this.setState({
        visible2: false,
        modalData: "",
      });
    }
  };

  onFinish = (values) => {
    console.log(values);
  };

  columns = [
    {
      title: "ชื่อ",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "เบอร์โทร",
      dataIndex: "phone_number",
      key: "phone_number",
      responsive: ["xl"],
    },
    {
      title: "ธนาคาร",
      dataIndex: "bank",
      key: "bank",
      responsive: ["xl"],
    },
    {
      title: "เลขบัญชี",
      dataIndex: "bank_number",
      key: "bank_number",
      responsive: ["xl"],
    },
    {
      title: "วันที่สมัคร",
      dataIndex: "created_at",
      key: "created_at",
      responsive: ["xl"],
    },
    {
      title: "ไลน์ ไอดี",
      dataIndex: "line_id",
      key: "line_id",
      responsive: ["xl"],
    },

    // { title: "Address", dataIndex: "address", key: "address" },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (text, row) => {
        return (
          <Space size="middle">
            {/* eslint-disable-next-line */}
            <a onClick={() => this.showModal1(text)}>สร้างผู้ใช้</a>
            {/* eslint-disable-next-line */}
            <a onClick={() => this.showModal2(text)}>แก้ไข</a>
            {/* eslint-disable-next-line */}
            <a onClick={() => this.showModalDelete(text)}>ลบ</a>
          </Space>
        );
      },
    },
  ];

  render() {
    return (
      <>
        <h1>สมาชิกทั้งหมด</h1>

        <ListUser />

        <ViewUser
          visible={this.state.visible1}
          data={this.state.modalView}
          onClose={this.onCloseEdit1}
        />

        <EditUser
          visible={this.state.visible2}
          data={this.state.modalData}
          onClose={this.onCloseEdit}
          onReload={this.reLoad}
        />

        <DeleteUser
          visible={this.state.visibleDelete}
          data={this.state.modalView}
          userType={this.state.type}
          onClose={this.handleCancel1}
          onReload={this.reLoad}
        />
      </>
    );
  }
}

export default UserManagement;
