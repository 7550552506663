import React, { Component } from 'react'

import { Modal, Button } from 'antd'

import UserService from '../../services/UserService'

class DeleteUser extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
  }

  state = {
    visible: this.props.visible,
    data: this.props.data,
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    this.props.onClose(true)
    this.props.onReload(true)
  }

  handleConfirm = async (e) => {
    let data = {}
    if (this.props.userType === 'User') {
      data = {
        userId: e.user_id,
        userType: this.props.userType,
      }
    }
    
    await UserService.deleteUser(data)
      .then((res) => {
        if (data.userType === 'User') {
          console.log('DeleteUser', res.data)
        } else {
          console.log('DeletePreUser', res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })

    this.setState({
      visibleDelete: false,
    })
    this.handleCancel()
  }

  handleCancelConfirm = () => {
    this.setState({
      visibleDelete: false,
    })
  }

  render() {
    return (
      <>
        <Modal
          destroyOnClose={true}
          title="Confirm"
          visible={this.props.visible}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="1"
              type="primary"
              danger
              onClick={() => this.handleConfirm(this.props.data)}
            >
              ลบ
            </Button>,
          ]}
        >
          <p>
            คุณต้องการลบ <b>{this.props.data.user_id}</b> ?{' '}
          </p>
        </Modal>
      </>
    )
  }
}

export default DeleteUser
