import React, { Component } from "react";

import { Modal, Form, Input, Button, Tooltip } from "antd";

import UserService from "../../services/UserService";

import {
  InfoCircleOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";

const layout = {
  // labelCol: { span: 8 },
  // wrapperCol: { span: 16 },
};

const admin = localStorage.getItem("user");
const dataAdmin = JSON.parse(admin);

const validateMessages = {
  // eslint-disable-next-line
  required: "${label} is required!",
  // eslint-disable-next-line
  types: {
    // eslint-disable-next-line
    email: "${label} is not validate email!",
    // eslint-disable-next-line
    number: "${label} is not a validate number!",
  },
  number: {
    // eslint-disable-next-line
    range: "${label} must be between ${min} and ${max}",
  },
};
// eslint-disable-next-line
class EditUser extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  state = {
    visible: this.props.visible,
    data: this.props.data,
    dataNew: "",
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.props.onClose(true);

    this.props.onReload(true);
  };

  setData = (e) => {
    this.setState({ dataNew: e });
  };

  handleDelete = (e) => {
    console.log("Delete");
  };

  onFinish = async (e) => {
    const filter = {
      user_id: e.username,
      admin_id: dataAdmin.admin_id,
    };
    const conditions = {
      user_id: e.username,
      password: e.password,
      line_id: e.lineId,
      phone_number: e.Phone,
    };

    await UserService.updateUser(filter, conditions)
      .then((response) => {
        this.setData(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    this.handleCancel();
  };

  render() {
    return (
      <div>
        <Modal
          title="Edit User"
          visible={this.props.visible}
          onCancel={this.handleCancel}
          footer={[]}
        >
          <Form
            {...layout}
            name="nest-messages"
            onFinish={this.onFinish}
            initialValues={{
              // eslint-disable-next-line
              ["phone"]: this.props.data.phone_number,
              // eslint-disable-next-line
              ["username"]: this.props.data.user_id,
              // eslint-disable-next-line
              ["lineId"]: this.props.data.line_id,
            }}
            validateMessages={validateMessages}
          >
            <Form.Item
              label="ชื่อผู้ใช้"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                readOnly
                style={{ marginLeft: "6%", width: "96%" }}
                placeholder="Enter your username"
                prefix={<UserOutlined className="site-form-item-icon" />}
                suffix={
                  <Tooltip title="Extra information">
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>

            <Form.Item
              label="รหัสผ่าน"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                style={{ width: "97%", marginLeft: "5%" }}
                // placeholder="input password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                // style={{ marginTop: "5%" }}
              />
            </Form.Item>

            <Form.Item
              label="เบอร์โทร"
              name="phone"
              rules={[{ required: true, message: "Please input your Phone!" }]}
            >
              <Input
                style={{ marginLeft: "5%", width: "97%" }}
                placeholder="input Phone"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                // style={{ marginTop: "5%" }}
              />
            </Form.Item>
            <Form.Item
              label="ไลน์ ไอดี"
              name="lineId"
              rules={[{ required: true, message: "Please input your lineId!" }]}
            >
              <Input
                style={{ marginLeft: "5%", width: "97%" }}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                // style={{ marginTop: "5%" }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                style={{ float: "right" }}
                // onClick={this.handleOk}
              >
                แก้ไข
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default EditUser;
