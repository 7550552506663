import React, { Component } from "react";

import { Form, Input, Button, message } from "antd";
import UserService from "../../services/UserService";
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 9,
    span: 16,
  },
};

const codeLayout = {
  wrapperCol: {
    offset: 10,
    span: 3,
  },
};

const admin = localStorage.getItem("user");
const dataAdmin = JSON.parse(admin);
class CodeCredit extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  state = {
    codeGen: "",
  };

  onFinish = (values) => {
    UserService.getCode(values.code, dataAdmin.admin_id)
      .then((res) => {
        console.log(res.data);
        if (res.data.message) {
          message.error({
            content: "credit ไม่พอ",
            className: "custom-class",
            style: {
              marginTop: "20vh",
              fontSize: "16px",
            },
          });
        }
        this.setState({ codeGen: res.data });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  copyCodeToClipboard = () => {
    const el = this.textArea;
    el.select();
    document.execCommand("copy");
    message.success({
      content: "Copy to clipboard!",
      style: {
        marginTop: "5vh",
        fontSize: "16px",
      },
    });
  };
  render() {
    return (
      <>
        <Form {...layout} name="control-ref" onFinish={this.onFinish}>
          <Form.Item name="code" label="จำนวนเงิน">
            <Input />
          </Form.Item>
          <Form.Item name="codeGenerate" {...codeLayout}>
            <div onClick={() => this.copyCodeToClipboard()}>
              <textarea
                readOnly
                style={{
                  border: "1px solid",
                  borderRadius: "16px",
                  padding: "6%",
                  backgroundColor: "white",
                  textAlign: "center",
                  height: "40px",
                  width: "90%",
                }}
                ref={(textarea) => (this.textArea = textarea)}
                value={this.state.codeGen}
              />
            </div>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Generate Code
            </Button>
            <Button
              size="default"
              style={{ margin: "0 8px" }}
              onClick={() => this.copyCodeToClipboard()}
            >
              Copy Code
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}
export default CodeCredit;
