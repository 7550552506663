import React, { Component } from "react";

import { Modal, Form, Button, Input, message } from "antd";

import UserService from "../../services/UserService";

// const admin = localStorage.getItem("user");
// const dataAdmin = JSON.parse(admin);
class DepositModal extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  state = {
    visible: this.props.visible,
    data: this.props.data,
  };

  handleCancel = (e) => {
    this.props.onReload(true);
    this.props.onClose(true);
  };

  handleDeposit = async (data) => {
    if (this.props.type === "User") {
      const newData = {
        credit: data.amount || 0,
        userId: data.user_id,
        userType: this.props.type,
      };

      await UserService.userTopup(newData)
        .then((response) => {
          console.log(response.data);
          if (response.data.message === "Not enough credit") {
            message.error({
              content: "Not enough credit",
              className: "custom-class",
              style: {
                marginTop: "20vh",
                fontSize: "16px",
              },
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      const newData = {
        credit: data.amount,
        adminId: data.admin_id,
        userType: data.admin_type || "admin",
      };

      await UserService.adminTopup(newData)
        .then((response) => {
          console.log(response.data);
          if (response.data.message === "Not enough credit") {
            message.error({
              content: "Not enough credit",
              className: "custom-class",
              style: {
                marginTop: "20vh",
                fontSize: "16px",
              },
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    this.handleCancel();
  };

  render() {
    // console.log(this.props.data);
    return (
      <>
        <Modal
          destroyOnClose={true}
          title="เพิ่มเครดิต"
          visible={this.props.visible}
          onCancel={this.handleCancel}
          footer={[]}
        >
          <Form
            onFinish={this.handleDeposit}
            layout="vertical"
            name="userForm"
            initialValues={{
              // eslint-disable-next-line
              ["user_id"]: this.props.data.user_id,
              // eslint-disable-next-line
              ["admin_id"]: this.props.data.admin_id,
              // eslint-disable-next-line
              ["admin_type"]: this.props.data.admin_type,
            }}
          >
            <Form.Item name="amount" label="จำนวน">
              <Input />
            </Form.Item>

            <Form.Item name="admin_id" hidden>
              <Input defaultValue={this.props.data.admin_id} />
            </Form.Item>
            <Form.Item name="user_id" hidden>
              <Input defaultValue={this.props.data.user_id} />
            </Form.Item>
            <Form.Item name="admin_type" hidden>
              <Input defaultValue={this.props.data.admin_type} />
            </Form.Item>

            <Form.Item>
              <Button
                style={{ float: "right" }}
                key="submit"
                type="primary"
                htmlType="submit"
              >
                ตกลง
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
export default DepositModal;
